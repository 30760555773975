(function($) {
  FastClick.attach(document.body);

  mobileOpenMenu();
  countrySelection();
  searchBoxToggle();
  heroGallery();
  menuAction();

})(jQuery);

function mobileOpenMenu(){
	var openMenu = $('.js-btnOpenMenu');
	var closeMenu = $('.js-btnCloseMenu');
	var navigation = $('.js-navigation');

	openMenu.click(function(event) {
		navigation.addClass('opened');
	});
	closeMenu.click(function(event) {
		navigation.removeClass('opened');
	});
}

function countrySelection() {
	var selectCountry = $('.select-country');
	var dropdownCountry = $('.dropdown-menu-country');
	selectCountry.click(function(event) {
		dropdownCountry.toggleClass('showed');
	});
}

function searchBoxToggle(){
	var btnOpenSearchBox = $('.btnOpenSearch');
	var searchbox = $('.searchBox-wrapper');

	btnOpenSearchBox.click(function(event) {
		searchbox.toggleClass('showed');
	});
}

function heroGallery() {
	var heroCarousel = $('.hero-carousel');

	heroCarousel.slick({
		
	})
}

function menuAction() {
	  var t = $(".js-close-menu"),
	    i = $(".js-open-menu"),
	    n = $(".siteMobileHeader__menu");
	  t.click(function() {
	    n.removeClass("show-menu");
	    $(".js-has-children").removeClass("active");
	    setTimeout(function() {
	      $(".menu-nav").removeClass("active")
	    }, 300)
	  });
	  i.click(function() {
	    n.addClass("show-menu")
	  })
	}

